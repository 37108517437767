<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div v-if="contract_proposal" class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ contract_proposal && contract_proposal.entity ? 'EDIÇÃO CONTRATO' : 'EDIÇÃO PROPOSTA' }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ contract_proposal && contract_proposal.entity ? 'EDIÇÃO CONTRATO' : 'EDIÇÃO PROPOSTA' }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div v-if="load_counter >= 7" :class="contract_proposal.entity ? '' : 'd-flex justify-content-center'">
        <ContractProposalNavigation
          :contractUuid="contract_proposal.uuid"
          :contractStatus="contract_proposal.status"
          :entity="contract_proposal.entity"
          :activeGeneral="true"
        />
      </div>
    </base-header>

    <div class="container-fluid">
      <!-- Start Card ContractProposal -->
      <card>
        <validation-observer v-slot="{ invalid }" ref="formValidator">
          <div class="row">
            <div class="col-md-12 mb-4 border-left  border-primary">
              <div class="mt-2" style="display: flex; align-items: center;">
                <span class="h2 display-4" style="margin-right: 10px;">
                  {{ contract_proposal.code }}
                </span>
                <span class="m-0 p-1 mt-n1">
                  <base-button
                    :disabled="loading"
                    block
                    size="sm"
                    :type="getButtonType(contract_proposal.status, contract_proposal.entity)"
                    class="text-uppercase"
                    @click.prevent="handleChangeStatus(contract_proposal, contract_proposal.status)"
                  >
                    <span
                      style="font-size: 11px;"
                      v-if="!contract_proposal.entity && contract_proposal.status === ContractProposalEnum.ACTIVE">
                      PROPOSTA
                    </span>
                    <span v-else style="font-size: 11px;">
                      {{ getStatusText(contract_proposal.status) }}
                    </span>
                  </base-button>
                </span>
                <span v-if="contract_proposal && !contract_proposal.entity" class="m-0 p-1 mt-n1">
                  <base-button
                    block
                    size="sm"
                    type="warning"
                    class="text-uppercase"
                    @click="handleShowModalSearchEntity(contract_proposal)"
                  >
                    <img src="/img/icons/icons8/ios/agreement-black.png" class="mr-1" height="17"
                         style="filter: invert(1);"/>
                    Gerar contrato
                  </base-button>
                </span>
              </div>
              <div>
                <span class="display-5 mb-1">
                  <b>{{ contract_proposal.construction.customer_construction.customer_name }}</b>
                </span>
                <span class="display-5">
                  ({{ contract_proposal.construction.construction_name }})
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="form-group row m-0 p-0 ">
                <div class="col-md-6 mt-1">
                  <div v-if="contract_proposal.lastApproval && contract_proposal.lastApproval.situation">
                    <div class="pb-1 pt-1 col-form-label form-control-label">
                      Situação
                    </div>
                    <base-input input-group-classes="input-group-sm" disabled
                                v-model="contract_proposal.lastApproval.situation.name">
                    </base-input>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-1">
                  <!-- CARD CLIENTE -->
                  <div v-if="contract_proposal && contract_proposal.entity" class="col-12 col-md-6 mt-n3">
                    <div class="card p-4">
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span class="h3 new-default-black-font p-1 mb-0" style="font-size: 12px;">
                          <img height="30px" src="/img/icons/icons8/ios/contract-job-primary.png" class="mr-1"/>
                          Cliente
                        </span>
                        <span
                          style="width: 115px; margin-left: auto;"
                          class="btn btn-sm p-1 text-center float-right invert-color"
                          :class="totalEntityAttachments > 0 ? 'btn-outline-success' : 'btn-outline-dark'"
                          v-if="load_counter >= 7 && (contract_proposal && contract_proposal.entity)"
                        >
                          <a
                            href="#"
                            @click.prevent="handleShowEntityAttachments(
                              contract_proposal.entity.id,
                              contract_proposal.entity.entity_name,
                              contract_proposal.entity.document
                            )"
                          >
                            <img
                              :src="
                                totalEntityAttachments > 0
                                  ? '/img/icons/paperclip-green.png'
                                  : '/img/icons/paperclip.png'
                              "
                              class="invert-on-hover"
                              width="25px"
                            />
                            <span
                              :class="totalEntityAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                              class="invert-on-hover"
                              style="font-size: 12px;"
                            >
                              ANEXOS
                            </span>
                            <span
                              style="font-size: 12px;"
                              :class="totalEntityAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                              class="invert-on-hover"
                            >
                              {{ totalEntityAttachments }}
                            </span>
                          </a>
                        </span>
                        <span
                          style="width: 115px;"
                          class="btn btn-sm btn-outline-warning p-1 text-center float-right invert-color"
                          @click="handleEditEntity(contract_proposal.entity.uuid)"
                        >
                          <img height="25px" src="/img/icons/create-new.png" class="ml-2 invert-on-hover"/>
                          <span style="font-size: 12px;">EDITAR</span>
                        </span>
                      </div>
                      <a
                        href="#"
                        class="text-right mt-2"
                        @click.prevent="isHideEntityCard = !isHideEntityCard"
                      >
                        <span v-if="isHideEntityCard" class="text-dark" style="font-size: 11px;">
                          Expandir
                        </span>
                        <span v-else class="text-dark" style="font-size: 11px;">
                          Ocultar
                        </span>
                        <img height="17px" src="/img/icons/icons8/ios/arrow-up.png" class="mr-2"/>
                      </a>
                      <hr v-if="!isHideEntityCard" class="new-default-black mt-2 mb-2">
                      <div v-if="!isHideEntityCard" class="row my-1 align-items-center">
                        <div class="col-12">
                          <div class="form-group row m-0 p-0 mb-1">
                            <div class="col-md-4 mt-n2 mb-2"/>
                            <div
                              v-if="!contract_proposal.has_payments && $hasPrivilege(1)"
                              class="col-md mt-n1 mb-2"
                            >
                              <base-button
                                class="text-uppercase"
                                block
                                size="sm"
                                type="default"
                                @click.prevent="handleSearchCpfCnpj()"
                              >
                                <i class="fas fa-edit"></i> Alterar tomador
                              </base-button>
                            </div>
                            <div class="col-md-12 text-right">
                            </div>
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Nome:
                            </label>
                            <div class="col-md-8">
                            <span
                              v-if="contract_proposal && contract_proposal.service_receiver"
                              :title="contract_proposal.service_receiver.entity_name"
                              class="form-control form-control-sm text-truncate"
                            >
                              {{
                                contract_proposal &&
                                contract_proposal.service_receiver &&
                                contract_proposal.service_receiver.entity_name
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Documento:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.service_receiver &&
                                contract_proposal.service_receiver.document
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Logradouro:
                            </label>
                            <div class="col-md-8">
                            <span
                              v-if="contract_proposal && contract_proposal.service_receiver && contract_proposal.service_receiver.address"
                              :title="`
                                ${contract_proposal.service_receiver.address.address},
                                ${contract_proposal.service_receiver && contract_proposal.service_receiver.address.number}
                              `"
                              class="form-control form-control-sm text-truncate"
                            >
                              {{
                                contract_proposal &&
                                contract_proposal.service_receiver &&
                                contract_proposal.service_receiver.address.address
                              }},
                              {{
                                contract_proposal.service_receiver &&
                                contract_proposal.service_receiver.address.number
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Bairro:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.service_receiver &&
                                contract_proposal.service_receiver.address.district
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Município:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.service_receiver &&
                                contract_proposal.service_receiver.address.city
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              uf:
                            </label>
                            <div class="col-md-3">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.service_receiver &&
                                contract_proposal.service_receiver.address.state
                              }}
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- CARD OBRA -->
                  <div class="col-12 col-md-6 mt-n3">
                    <div class="card p-4">
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span class="h3 new-default-black-font p-1 mb-0" style="font-size: 14px;">
                          <img height="30px" src="/img/icons/icons8/ios/brick-wall.png" class="mr-3"/>
                          Obra
                        </span>
                        <span
                          style="width: 115px; margin-left: auto;"
                          class="btn btn-sm p-1 text-center float-right invert-color"
                          :class="totalConstructionAttachments > 0 ? 'btn-outline-success' : 'btn-outline-dark'"
                          @click.prevent="handleShowConstructionAttachments(
                            contract_proposal.construction.id,
                            contract_proposal.construction.construction_name
                          )"
                          v-if="load_counter >= 7"
                        >
                          <a
                            href="#"
                            @click.prevent="handleShowConstructionAttachments(
                              construction.id,
                              construction.construction_name
                            )"
                          >
                            <img
                              :src="
                                totalConstructionAttachments > 0
                                  ? '/img/icons/paperclip-green.png'
                                  : '/img/icons/paperclip.png'
                              "
                              class="invert-on-hover"
                              width="25px"
                            />
                            <span
                              :class="totalConstructionAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                              class="invert-on-hover"
                              style="font-size: 12px;"
                            >
                              ANEXOS
                            </span>
                            <span
                              style="font-size: 12px;"
                              :class="totalConstructionAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                              class="invert-on-hover"
                            >
                              {{ totalConstructionAttachments }}
                            </span>
                          </a>
                        </span>

                        <span
                          style="width: 115px;"
                          class="btn btn-sm btn-outline-warning p-1 text-center float-right invert-color"
                          v-if="contract_proposal && contract_proposal.construction"
                          @click="handleEditConstruction()"
                        >
                          <img height="25px" src="/img/icons/create-new.png" class="ml-2 invert-on-hover"/>
                          <span style="font-size: 12px;">EDITAR</span>
                        </span>
                      </div>
                      <a
                        href="#"
                        class="text-right text-dark mt-2"
                        style="font-size: 11px;"
                        @click.prevent="isHideConstructionCard = !isHideConstructionCard"
                      >
                        <span v-if="isHideEntityCard" class="text-dark" style="font-size: 11px;">
                          Expandir
                        </span>
                        <span v-else class="text-dark" style="font-size: 11px;">
                          Ocultar
                        </span>
                        <img height="17px" src="/img/icons/icons8/ios/arrow-up.png" class="mr-2"/>
                      </a>
                      <hr v-if="!isHideConstructionCard" class="new-default-black mt-2 mb-2">
                      <div v-if="!isHideConstructionCard" class="row my-1 align-items-center">
                        <div class="col-12">
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Nome da construção:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.construction &&
                                contract_proposal.construction.construction_name
                              }}
                            </span>
                            </div>
                          </div>

                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Central:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal.plant.name
                              }}
                            </span>
                            </div>
                          </div>

                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Logradouro:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.construction &&
                                contract_proposal.construction.default_address.address +
                                ", " +
                                contract_proposal.construction.default_address.number
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Bairro:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.construction &&
                                contract_proposal.construction.default_address.district
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              Município:
                            </label>
                            <div class="col-md-8">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.construction &&
                                contract_proposal.construction.default_address.city
                              }}
                            </span>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                              uf:
                            </label>
                            <div class="col-md-3">
                            <span class="form-control form-control-sm">
                              {{
                                contract_proposal &&
                                contract_proposal.construction &&
                                contract_proposal.construction.default_address.state
                              }}
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CARD CONTRATO -->
            <div class="row mb-3 mt-n3">
              <div class="col-12">
                <div class="card p-4">
                  <div class="row">
                    <div class="col-md-8 show-md">
                      <div style="display: flex; align-items: center;">
                    <span class="mr-3 h3">
                      <img height="30px" src="/img/icons/icons8/ios/business.png" class="mr-2"/>
                      Contrato
                    </span>
                        <span class="mt-n2" style="width: auto;">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="contract_proposal.contract_uuid"
                            :items="contracts"
                            customKey="uuid"
                            :loading="loadingContract"
                            :disabled="loadingContract"/>
                        </base-input>
                      </validation-provider>
                    </span>
                      </div>
                    </div>
                    <div class="col-md-12 show-mobile">
                      <div style="display: flex; align-items: center;">
                    <span class="mr-3 h3">
                      <img height="30px" src="/img/icons/icons8/ios/business.png" class="mr-2"/>
                      Contrato
                    </span>
                      </div>
                    </div>
                    <div class="col-md-12 show-mobile mb-2">
                         <span class="mt-n2" style="width: auto;">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="contract_proposal.contract_uuid"
                            :items="contracts"
                            customKey="uuid"
                            :loading="loadingContract"
                            :disabled="loadingContract"/>
                        </base-input>
                      </validation-provider>
                    </span>
                    </div>
                    <div class="col-md-4 mt-1 text-right">
                      <el-popover width="350" trigger="click" class="p-0 float-right pr-0 ml-3">
                        <div class="row p-1 pl-2">
                          <div class="col-6">
                            <div class="form-control-label">
                              REIDI
                            </div>
                          </div>
                          <div class="col-6" @click="validPermissionCanIncludeReidi">
                            <base-switch
                              :disabled="!$_financial_limit.invoice_can_include_reidi"
                              v-model="contract_proposal.reidi"
                              type="danger"
                              offText="NÃO"
                              onText="SIM"
                            ></base-switch>
                          </div>
                          <div class="col-12">
                            <div class="form-control-label">
                              INSC. ESTADUAL
                            </div>
                          </div>
                          <div class="col-12">
                            <input
                              v-model="contract_proposal.state_registration"
                              class="form-control form-control-sm"/>
                          </div>
                          <div class="col-6 mt-2">
                            <div class="form-control-label">
                              FIXAR CNPJ EMISSOR?
                            </div>
                          </div>
                          <div class="col-6 mt-2">
                            <base-switch
                              v-model="contract_proposal.issuer_fixed"
                              type="danger"
                              @input="validIssuerFixed"
                              offText="NÃO"
                              onText="SIM"
                            ></base-switch>
                          </div>
                          <div class="col-12">
                            <PuzlSelect
                              :disabled="!contract_proposal.issuer_fixed"
                              :label="'business_name'"
                              v-model="contract_proposal.company_plant_issuer_id_fixed"
                              :items="company_plant_issuers"
                            />
                          </div>
                        </div>
                        <base-button
                          slot="reference"
                          outline type="primary">
                          <i class="fa-solid fa-wand-sparkles"></i> REGRAS ESPECIAIS
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <div class="col-md-4">
                        <div class="pb-1 col-form-label form-control-label">
                          Central
                          <span class="text-danger">&nbsp;*&nbsp;</span>
                        </div>
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <PuzlSelect
                              v-model="contract_proposal.company_plant_id"
                              :items="plants"
                              @change="checkPlantGeneralSettings();contract_proposal.company_plant_issuer_id_fixed = null"
                              :loading="loadingPlant"
                              :disabled="
                                  loadingPlant ||
                                  [5,0].includes(contract_proposal.status) ||
                                  (opened_billing > 0 && !$_commercial_permission.contract_editor_and_seller)
                                "
                            />
                          </base-input>
                        </validation-provider>
                      </div>
                      <div class="col-md-4">
                        <div class="pb-1 col-form-label form-control-label">
                          Vendedor
                          <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <PuzlSelect
                              v-model="contract_proposal.seller"
                              :items="users" customKey="uuid"
                              :loading="loadingUsers"
                              :disabled="
                                loadingUsers ||
                                [5,0].includes(contract_proposal.status) ||
                                (opened_billing > 0 && !$_commercial_permission.contract_editor_and_seller)
                              "
                            />
                          </base-input>
                        </validation-provider>
                      </div>
                      <div class="col-md-4">
                        <div class="pb-1 col-form-label form-control-label">
                          Volume médio por BT
                          <span class="text-danger">&nbsp;*</span>
                          <span v-if="contract_proposal.average_volume_per_bt < 3">
                            <i class="fas fa-exclamation-triangle text-danger ml-1 mr-1"></i>
                            <small class="text-dark" style="color: #7e7e7e">Valor mínimo: 3</small>
                          </span>
                        </div>
                        <validation-provider rules="required|min_value:3">
                          <input-limit
                            :disabled="[5,0].includes(contract_proposal.status)"
                            :options="{
                              min: 0,
                              max: 49.9,
                              decimals: 1
                            }"
                            :start_value="contract_proposal.average_volume_per_bt"
                            v-model="contract_proposal.average_volume_per_bt"
                            @value="contract_proposal.average_volume_per_bt = $event"
                          >
                            <small class="input-group-sm p-0 m-0">
                              m<sup>3</sup>
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                      <div class="col-md-4">
                        <div class="pb-1 col-form-label form-control-label">
                          CNO
                          <span 
                            v-show="contract_proposal.entity && is_cno_required_prop_contracts"
                            class="text-danger"
                          >
                            &nbsp;*
                          </span>
                        </div>
                        <validation-provider
                          :rules="contract_proposal.entity && is_cno_required_prop_contracts ? 'required|min:14' : 'min:14'"
                          v-slot="{errors}"
                        >
                          <base-input input-classes="form-control-sm">
                            <input
                              class="form-control form-control-sm"
                              maxlength="15"
                              v-mask="'##.###.#####/##'"
                              v-model="contract_proposal.cno"/>
                          </base-input>
                        </validation-provider>
                      </div>
                      <div class="col-md-4">
                        <div class="pb-1 col-form-label form-control-label">
                          Cód. de obra da prefeitura
                          <span
                          v-if="
                            contract_proposal.entity && is_municipal_work_code_required_prop_contracts
                          "
                            class="text-danger"
                          >
                            &nbsp;*
                          </span>
                        </div>
                        <validation-provider
                          :rules="
                            contract_proposal.entity && is_municipal_work_code_required_prop_contracts
                              ? 'required' : ''
                          "
                        >
                          <base-input input-group-classes="input-group-sm">
                            <input class="form-control form-control-sm"
                                   v-model="contract_proposal.city_hall_construction_code"/>
                          </base-input>
                        </validation-provider>
                      </div>
                      <div class="col-md-4">
                        <div class="pb-1 col-form-label form-control-label">
                          Art/nº de controle do encapsulamento
                          <span v-if="contract_proposal.entity && is_art_encapsulation_required" class="text-danger">
                            &nbsp;*
                          </span>
                        </div>
                        <validation-provider :rules="contract_proposal.entity && is_art_encapsulation_required && 'required' || ''">
                          <base-input input-group-classes="input-group-sm">
                            <input class="form-control form-control-sm" v-model="contract_proposal.art_code"/>
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3 mt-n3">
            <!-- CARD FATURAMENTO -->
            <div class="col-12 col-md-6 mt-n3">
              <div class="card p-4">
                <div class="row">
                  <div class="col-md-6">
                    <div style="display: flex; align-items: center;">
                  <span class="mr-3 h3">
                    <img height="30px" src="/img/icons/check.png" class="mr-2"/>
                    Faturamento
                  </span>
                    </div>
                  </div>
                  <div class="col-md-6 show-md">
                    <div class="d-flex float-right">
                      <div class="form-control-label pr-2">
                        POR MEDIÇÃO
                      </div>
                      <div>
                        <base-switch
                          v-model="contract_proposal.measurement"
                          type="primary"
                          offText="NÃO"
                          onText="SIM"
                        ></base-switch>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 show-mobile">
                    <div class="d-flex">
                      <div class="form-control-label pr-2">
                        POR MEDIÇÃO
                      </div>
                      <div>
                        <base-switch
                          v-model="contract_proposal.measurement"
                          type="primary"
                          offText="NÃO"
                          onText="SIM"
                        ></base-switch>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="new-default-black mt-2 mb-2">
                <div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-md-12 mt-n2 mb-2" v-if="contract_proposal.necessary_approvation">
                      <div class="col-form-label form-control-label mb-n2">
                        Aprovação financeira
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <base-button
                        :class="contract_proposal.approvation === 1 && 'active'"
                        :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                        :title="!$hasPermission('additional_permissions.financial_approvation') ? 'Sem permissão para alterar' : ''"
                        type="success"
                        size="sm"
                        @click.prevent="contract_proposal.approvation = 1"
                        outline
                      >
                        Aprovado
                      </base-button>
                      <base-button
                        :class="contract_proposal.approvation === 2 && 'active'"
                        :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                        :title="!$hasPermission('additional_permissions.financial_approvation') ? 'Sem permissão para alterar' : ''"
                        type="danger"
                        size="sm"
                        @click.prevent="contract_proposal.approvation = 2"
                        outline
                      >
                        Reprovado
                      </base-button>
                      <br>
                      <span v-if="contract_proposal.approvation_user">
                        Revisado por: <strong>{{ contract_proposal.approvation_user }}</strong>
                      </span>
                      <span v-if="contract_proposal.approvation === 1 && !contract_proposal.approvation_user">
                        Aprovado automaticamente pelo sistema.
                      </span>
                    </div>

                    <div class="col-md-6 mb-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Métodos de pagamento
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.payment_methods_ids"
                            :items="payment_methods"
                            :multiple="true"
                            :loading="loadingMethod"
                            @input="setPaymentTermsByMethod()"
                            :disabled="[5,0].includes(contract_proposal.status)"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mb-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Condições de pagamento
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.payment_terms_uuids"
                            :items="payment_terms_by_principal"
                            customKey="uuid"
                            :multiple="true"
                            @input="setMethods"
                            :loading="loadingPaymentTerm"
                            @change="setAll()"
                            :disabled="loadingPaymentTerm || !contract_proposal.payment_methods_ids.length || [5,0].includes(contract_proposal.status)"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Observações para nota fiscal de serviço
                        <span
                          class="text-danger"
                          v-if="contract_proposal.entity && contract_proposal.entity.is_nfse_observations_required"
                        >
                          &nbsp;*
                        </span>
                      </div>
                      <div
                        class="mb-1"
                        v-if="contract_proposal.entity && contract_proposal.entity.is_nfse_observations_required"
                      >
                        <a href="#" class="text-dark" @click.prevent="SetServiceInvoiceNotes()">
                          <b style="font-size: 12px"> Número de empenho: </b>
                          <span
                            class="badge mr-1 ml-1"
                            style="background-color: #ffff; border: 1px solid #ccc; padding: 4px; border-radius: 4px;"
                          >
                            <i style="font-size: 15px" class="zoom pointer fa-regular fa-clipboard text-dark"></i>
                            <span style="font-size: 10px" class="font-weight-500">
                              Preencher
                            </span>
                          </span>
                        </a>
                      </div>
                      <validation-provider
                        :rules="
                          contract_proposal.entity &&
                          contract_proposal.entity.is_nfse_observations_required ? 'required' : ''
                        "
                        v-slot="{errors}"
                      >
                        <base-input input-classes="form-control-sm">
                          <textarea 
                            v-model="contract_proposal.observation"
                            maxlength="400"
                            class="form-control"
                            rows="2"
                          />
                          <small class="text-muted">
                            {{ (contract_proposal.observation ? contract_proposal.observation.length : 0) }}/400
                          </small>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Orientações para o faturamento
                      </div>
                      <base-input input-classes="form-control-sm">
                        <textarea
                          v-model="contract_proposal.billing_guidelines"
                          maxlength="400"
                          class="form-control"
                          rows="2"
                        />
                        <small class="text-muted">
                          {{ (contract_proposal.billing_guidelines ? contract_proposal.billing_guidelines.length : 0) }}/400
                        </small>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CARD AUTOMATIZAÇÕES -->
            <div class="col-12 col-md-6 mt-n3">
              <div class="card p-4">
                <div
                  v-if="contract_proposal.payment_terms_uuids.length && !loadingMethod && !loadingPaymentTerm"
                  style="display: flex; align-items: center;"
                >
                  <span class="mr-3 h3">
                    <img height="30px" src="/img/icons/icons8/ios/bot.png" class="mr-2"/>
                    Automatizações
                  </span>
                </div>
                <hr class="new-default-black mt-2 mb-2">
                <section v-if="contract_proposal.payment_terms_uuids.length && !loadingMethod && !loadingPaymentTerm">
                  <div class="row p-0 m-0">
                    <div class="col-md-2">
                      <label class="label form-control-label">NF-e</label>
                      <base-input>
                        <base-switch
                          @input="setNfse"
                          v-model="contract_proposal.automatic_nfe"
                        />
                      </base-input>
                    </div>
                    <div class="col-md-2">
                      <label class="label form-control-label">NFS-e</label>
                      <base-input>
                        <base-switch
                          @input="unsetIssRetain()"
                          v-model="contract_proposal.automatic_nfse"
                        />
                      </base-input>
                    </div>
                    <div class="col-md-3">
                      <label class="label form-control-label">Faturamento</label>
                      <base-input>
                        <base-switch
                          @input="setAll()"
                          v-model="contract_proposal.automatic_billing"
                        />
                      </base-input>
                    </div>
                    <div v-if="contract_proposal.automatic_billing" class="col-md-2">
                      <label class="label form-control-label">Boleto</label>
                      <base-input>
                        <base-switch
                          @input="setTicket(contract_proposal.billing.automatic_ticket)"
                          v-model="contract_proposal.billing.automatic_ticket"
                          :disabled="!contract_proposal.automatic_billing || [5,0].includes(contract_proposal.status)"/>
                      </base-input>
                    </div>
                  </div>
                  <div v-if="contract_proposal.automatic_billing" class="row m-0 p-0">
                    <div class="col-md-6">
                      <label class="col-form-label form-control-label">
                        Método de pagamento
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </label>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.billing.payment_method_id"
                            :items="Payment_methods"
                            :loading="loadingMethod"
                            @input="getPaymentTerms()"
                            @change="getPaymentIntermediaries()"
                            :disabled="loadingMethod || [5,0].includes(contract_proposal.status)"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-6">
                      <label class="col-form-label form-control-label">
                        Condição de pagamento
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </label>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.billing.payment_term_id"
                            :items="filteredPaymentTermsByMethod"
                            @input="setRange"
                            :loading="loadingPaymentTerm"
                            :disabled="loadingPaymentTerm || !contract_proposal.billing.payment_method_id || [5,0].includes(contract_proposal.status)"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div v-if="contract_proposal.automatic_billing" class="row m-0 p-0">
                    <div class="col-md-6 mt-2">
                      <label class="col-form-label form-control-label">
                        Conta bancária
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </label>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.billing.bank_account_id"
                            :items="$_bank_accounts"
                            :labelMask="true"
                            :loading="loadingBankAccounts"
                            :disabled="[5,0].includes(contract_proposal.status)"/>
                        </base-input>
                      </validation-provider>
                    </div>

                    <div v-if="contract_proposal.automatic_billing" class="col-md-6 mt-2">
                      <div class="col-form-label form-control-label">
                        Frequência do Faturamento
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <div class="col-12 px-1">
                        <div class="card" style="width: 250px !important;">
                          <div class="card-body">
                            <div class="row mb-2">
                              <div class="col-4 p-1">
                                <button type="button"
                                        @click.prevent="contract_proposal.billing.range = 1;frequency_selecteds = []"
                                        :class="contract_proposal.billing.range === 1 ? 'btn-primary-active' : 'new-default-black-font'"
                                        class="btn base-button fs-11 new-btn-light  btn-block btn-light btn-sm"
                                >DIÁRIO
                                </button>
                              </div>
                              <div class="col-4 p-1">
                                <button type="button"
                                        @click.prevent="contract_proposal.billing.range = 2;frequency_selecteds = []"
                                        :class="contract_proposal.billing.range === 2 ? 'btn-primary-active' : 'new-default-black-font'"
                                        class="btn base-button fs-11 new-btn-light  btn-block btn-light btn-sm"
                                >SEMANA
                                </button>
                              </div>
                              <div class="col-4 p-1">
                                <button type="button"
                                        @click.prevent="contract_proposal.billing.range = 3;frequency_selecteds = []"
                                        :class="contract_proposal.billing.range === 3 ? 'btn-primary-active' : 'new-default-black-font'"
                                        class="btn base-button fs-11 new-btn-light  btn-block btn-light btn-sm"
                                >MÊS
                                </button>
                              </div>
                            </div>
                            <!--                              <template v-if="contract_proposal.billing.range === 1">-->
                            <!--                                <div class="row">-->
                            <!--                                  <div class="col-12 text-center">-->
                            <!--                                    <img data-v-42102372=""-->
                            <!--                                         src="https://uxwing.com/wp-content/themes/uxwing/download/time-and-date/checkmark-date-calendar-icon.png"-->
                            <!--                                         style="width: 35px;opacity: 55%;margin-top: 10px;">-->
                            <!--                                  </div>-->
                            <!--                                </div>-->
                            <!--                              </template>-->
                            <template v-if="contract_proposal.billing.range === 2">
                              <table>
                                <thead>
                                <tr>
                                  <th v-for="item in weekDays.slice(0, 4)">
                                    <span @click.prevent="setFrequency(item.id)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item.id) && 'frequency-selected'">{{
                                        item.name
                                      }}</span>
                                  </th>
                                </tr>
                                <tr>
                                  <th v-for="item in weekDays.slice(4, 6)">
                                    <span @click.prevent="setFrequency(item.id)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item.id) && 'frequency-selected'">{{
                                        item.name
                                      }}</span>
                                  </th>
                                </tr>
                                </thead>
                              </table>
                            </template>
                            <template v-if="contract_proposal.billing.range === 3">
                              <table>
                                <thead>
                                <tr>
                                  <th v-for="item in generateRange(1, 7)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <th v-for="item in generateRange(8, 14)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                <tr>
                                  <th v-for="item in generateRange(15, 21)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                <tr>
                                  <th v-for="item in generateRange(22, 28)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                <tr>
                                  <th v-for="item in generateRange(29, 31)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                </tbody>
                              </table>
                            </template>
                          </div>
                        </div>
                        <!--                        <table class="table">-->
                        <!--                          <tr>-->
                        <!--                            <th style="padding: 1px;border: none" v-for="item in generateRange(1, 7)"-->
                        <!--                                class="pointer">-->
                        <!--                                  <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                        :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                      item-->
                        <!--                                    }}</span>-->
                        <!--                            </th>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(8, 14)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(15, 21)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(22, 28)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(29, 31)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                        </table>-->
                      </div>
                    </div>
                  </div>

                </section>
              </div>
            </div>
          </div>
          <div class="row float-right mr-3">
            <div class="col-md-12" style="display: flex; align-items: center;">
              <base-button type="danger" @click="refresh()">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2"/>
                Cancelar
              </base-button>
              <base-button
                @click.native="handleSubmit()"
                type="success"
                native-type="submit"
                title="Salvar"
                v-bind:disabled="invalid || load_counter < 7"
                :class="loadingSave && 'btn-loading'"
              >
                <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2"/>
                Salvar
              </base-button>
            </div>
          </div>
        </validation-observer>
      </card>

      <card class="mt-n3">
        <div style="display: flex; align-items: center;">
          <span class="mr-3 h3">
            Informações Complementares
          </span>
        </div>
        <hr class="new-default-black mt-2 mb-2">
        <div>
          <div class="row d-flex justify-content-end">
            <div class="col-md-3 mr-2 d-flex align-items-center justify-content-end">
              <base-button
                type="success"
                class="text-capitalize"
                @click.prevent="handleCreateAdditionalObservation(contract_proposal.uuid)"
              >
                Nova
              </base-button>
            </div>
          </div>
          <div
            class="m-2 mt-3"
            v-for="(additional_information,
            indexAdditionalInformaticon) in contract_proposal.comments"
            :key="indexAdditionalInformaticon"
          >
            <card class="mb-1 border card--hover card-body-molde">
              <div class="row d-flex justify-content-between">
                <div class="col-md-4">
                  <h5>
                    {{ additional_information.created_at | parseDate }} | {{ additional_information.user }}
                  </h5>
                </div>
                <div class="col-md-7">
                  <h5>{{ additional_information.title || "" }}</h5>
                  <p>
                    {{ additional_information.observation || "" }}
                  </p>
                </div>
                <div class="col-md-1 d-flex align-items-center justify-content-center">
                  <button
                    type="button"
                    class="btn btn-sm rounded btn-danger"
                    @click.prevent="handleRemoveAdditionalInformation(
                      additional_information.id,
                      indexAdditionalInformaticon
                    )"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </card>
          </div>
        </div>
      </card>
    </div>
    <ModalChangeStatus @changeStatus="changeStatus" ref="changeStatus"/>
    <ModalSearchCpfCnpj @set_entity="set_entity" ref="searchCpfCnpj"/>
    <ModalCreateEntity ref="createEntity"/>
    <ModalCreateAdditionalObservation ref="createAdditionalObservation"/>
    <ModalEditEntity
      @updatedEntity="set_entity"
      @set_entity_name="updateCustomerNameAfterEntityEdit"
      ref="editEntity"
    />
    <ModalEditConstruction @updatedConstruction="updateConstruction" ref="editConstruction"/>
    <float-button :contract_proposal="contract_proposal"/>
    <ModalConstructionAttachmentList
      ref="modalConstructionAttachmentList"
      @updatedTotalAttachmentAfterUpload="updatedTotalConstructionAttachmentAfterUpload"
      @updateTotalAttachmentAfterDeletion="updateTotalConstructionAttachmentAfterDeletion"/>
    <ModalEntityAttachmentList
      ref="modalEntityAttachmentList"
      @storedAttachment="updatedTotalEntityAttachmentAfterUpload"
      @deletedLastAttachment="updateTotalEntityAttachmentAfterDeletion"/>
    <ModalSearchEntity ref="modalSearchEntity" @setEntity="set_entity"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ModalChangeStatus from "./_ModalChangeStatus";
import ModalSearchCpfCnpj from "./_ModalSearchCpfCnpj";
import ModalCreateEntity from "@/views/Modules/Configuration/Entity/Shared/_Create";
import ModalCreateAdditionalObservation from "./_ModalCreateAdditionalObservation";
import PuzlSelect from "@/components/PuzlSelect"
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {onlyNumbers} from "@/helpers";
import inputLimit from "@/components/Utils/InputLimit";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import ModalEditConstruction from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_Edit";
import {construction as $_totvs} from "@/plugins/microservices/totvs";
import FloatButton from "../components/FloatButton/Index.vue";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import ModalConstructionAttachmentList
  from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_ModalConstructionAttachmentList";
import ModalEntityAttachmentList from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityAttachmentList";
import ModalSearchEntity from "./_ModalSearchEntity";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";

export default {
  name: "EditContractProposal",
  components: {
    ModalChangeStatus,
    ModalSearchCpfCnpj,
    ModalCreateEntity,
    ModalCreateAdditionalObservation,
    PuzlSelect,
    SkeletonPuzlGrid,
    inputLimit,
    ModalEditEntity,
    ModalEditConstruction,
    FloatButton,
    ContractProposalNavigation,
    ModalConstructionAttachmentList,
    ModalEntityAttachmentList,
    ModalSearchEntity,
  },
  data() {
    return {
      loadingPlant: true,
      frequency_selecteds: [],
      block_iss: false,
      payment_terms_by_method: [],
      Payment_methods: [],
      loadingContract: true,
      loadingPaymentTerm: true,
      payment_intermediaries_exists: false,
      loadingMethod: false,
      loadingSave: false,
      loadingUsers: true,
      entity_is_company: false,
      loadingCompanyPlantIssuer: false,
      loadingPaymentIntermediaries: false,
      loadingBalance: false,
      payment_intemerdiaries_selected: [],
      invalid: false,
      loading: false,
      users: [],
      payment_terms_by_principal: [],
      weekDays: [
        {
          id: 0,
          name: 'DOM',
        },
        {
          id: 1,
          name: 'SEG',
        },
        {
          id: 2,
          name: 'TER',
        },
        {
          id: 3,
          name: 'QUA',
        },
        {
          id: 4,
          name: 'QUI',
        },
        {
          id: 5,
          name: 'SEX',
        },
        {
          id: 5,
          name: 'SAB',
        },
      ],
      range_frequency_options: [
        {
          id: 1,
          name: 'Diariamente'
        },
        {
          id: 2,
          name: 'Semanalmente'
        },
        {
          id: 3,
          name: 'Mensalmente'
        },
        {
          id: 4,
          name: 'Personalizado'
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      loader: null,
      load_counter: 0,
      // config gerais de central:
      is_cno_required_prop_contracts: false,
      is_municipal_work_code_required_prop_contracts: false,
      is_art_encapsulation_required: false,
      totalConstructionAttachments: null,
      totalEntityAttachments: null,
      isHideEntityCard: true,
      isHideConstructionCard: true,
      opened_billing: null,
      loadingConstructionAttachments: true,
      loadingEntityAttachments: true,
      ContractProposalEnum: ContractProposalEnum,
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      company_plant_issuers: "plant/getCompanyPlantIssuers",
      code: "contractProposal/getMaxCode",
      price_validity: "contractProposal/getPriceValidity",
      $_bank_accounts: 'bankAccount/fetch',
      contracts: 'contractContracts/fetch',
      payment_terms: "paymentTerm/fetch",
      payment_methods: "paymentMethod/fetchUsable",
      contract_proposal: "contractProposal/show",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      $_commercial_permission: "commercialPermission/show",
      $_financial_limit: "permissionFinancialLimit/show",
    }),
    filteredPaymentTermsByMethod() {
      return this.payment_terms_by_method.filter((item) => this.contract_proposal.payment_terms_uuids.includes(item.uuid));
    },
  },
  watch: {
    'contract_proposal.company_plant_id': function () {
      this.getCompanyPlantIssuers()
    },
    'load_counter'(value) {
      // Pausa o loading quando todas as requisições estiverem carregadas.
      if (value === 7) {
        this.loader.hide();
        this.$Progress.finish();
      }
    },
  },
  methods: {
    setFrequency(item) {
      if (this.frequency_selecteds.includes(item)) {
        const index = this.frequency_selecteds.indexOf(item);
        if (index !== -1) {
          this.frequency_selecteds.splice(index, 1);
        }
      } else {
        this.frequency_selecteds.push(item)
      }
    },
    openFrequencyPicker() {
      if (this.contract_proposal.billing.range === 4) {
        this.$refs['frequency-picker'].handleMouseEnter();
      } else {
        this.$refs['frequency-picker'].showPopper = false;
      }
    },
    validIssuerFixed() {
      if (!this.contract_proposal.issuer_fixed) {
        this.contract_proposal.company_plant_issuer_id_fixed = null;
      } else {
        const default_plant_issuer = this.company_plant_issuers.find((item) => item.default);
        if (default_plant_issuer) {
          return this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
      }
    },
    validPermissionCanIncludeReidi() {
      if (!this.$_financial_limit.invoice_can_include_reidi) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para realizar alteração'
        });
      }
    },
    setAll() {
      if (!this.contract_proposal.automatic_billing) {
        return
      }
      const companyPlant = this.plants.find(item => item.id === this.contract_proposal.company_plant_id)
      if (companyPlant) {
        this.contract_proposal.billing.bank_account_id = companyPlant.bank_account_id
      }
      this.setMethods()
    },
    unsetIssRetain() {
      if (!this.contract_proposal.automatic_nfse) {
        this.contract_proposal.iss_retain = false
      }
    },
    setTicket(val) {
      if (val) {
        this.contract_proposal.billing.payment_method_id = 5
      }
    },
    getPaymentIntermediaries(unset_payment_intermediary = true) {
      if (unset_payment_intermediary) {
        this.contract_proposal.billing.payment_intermediary_id = null
      }
      this.payment_intemerdiaries_selected = []
    },
    rangeOffTypes(range_off, type) {
      if (range_off > 1) {
        if (type === 0) {
          return range_off + ' dias'
        } else {
          return range_off + ' horas'
        }
      }
      if (type === 0) {
        return range_off + ' dia'
      } else {
        return range_off + ' hora'
      }
    },
    setPaymentTermsByMethod(clear = true) {
      if (clear) {
        this.contract_proposal.billing.range = null
        this.contract_proposal.payment_terms_uuids = []
        this.contract_proposal.billing.payment_intermediary_id = null
        this.payment_intermediaries_exists = false
      }
      let payment_terms = []
      if (this.entity_is_company) {
        payment_terms = this.payment_terms.filter((item) => item.legal_person)
      } else {
        this.block_iss = true
        payment_terms = this.payment_terms.filter((item) => item.physical_person)
      }
      this.payment_terms_by_principal = payment_terms.filter((item) => {
        return item.allow_in_proposal && item.payment_methods.some(r => this.contract_proposal.payment_methods_ids.includes(r.id))
      })
    },
    getPaymentTerms(clear = true) {
      if (clear) {
        this.contract_proposal.billing.payment_term_id = null
      }
      let paymentMethod = this.payment_methods.find(item => item.id === this.contract_proposal.billing.payment_method_id)
      this.payment_terms_by_method = this.payment_terms.filter(function (item) {
        let has_method = item.payment_methods.find(method => method.uuid === paymentMethod.uuid)
        if (has_method) {
          return item
        }
      })
      if (!this.contract_proposal.billing.payment_term_id &&
        this.filteredPaymentTermsByMethod.length === 1 && this.contract_proposal.automatic_billing) {
        this.contract_proposal.billing.payment_term_id = this.filteredPaymentTermsByMethod[0].id
        this.setRange()
      }
    },
    formatAccountLabel(item) {
      return item.name
    },
    setNfse() {
      if (!this.contract_proposal.automatic_nfe) {
        this.contract_proposal.automatic_nfse = false
        this.unsetIssRetain()
      }
    },
    generateRange(start, end) {
      return Array.from({length: (end - start + 1)}, (_, index) => start + index);
    },
    set_entity(entity) {
      this.contract_proposal.service_receiver = entity
      const document_length = onlyNumbers(entity.document).length
      if (document_length <= 11) {
        this.contract_proposal.iss_retain = false
        this.block_iss = true
      } else {
        this.block_iss = false
      }

      this.contract_proposal.entity = entity;
      this.fetchEntityAtachments();
      this.handleSubmit();
    },
    /**
     * Atualiza o nome do cliente de uma construção após a edição de um tomador,
     * caso o CPF/CNPJ do tomador já esteja cadastrado em cliente/obra.
     * @param {string} entityName
     */
    updateCustomerNameAfterEntityEdit(entityName) {
      this.contract_proposal.construction.customer_construction.customer_name = entityName;
    },
    /**
     * Atualiza dados da obra após edição.
     * @param {object} customerConstruction
     */
    updateConstruction(customerConstruction) {
      this.loadingConstruction = true;
      this.contract_proposal.construction = customerConstruction;
      /**
       * Após realizar a edição da obra, verifica se a cidade salva é igual
       * a cidade do endereço padrão do emissor de central (cnpj)
       */
      this.$store.dispatch('companyPlantIssuer/getIssuerCityByCompanyPlantId', this.contract_proposal.company_plant_id)
        .then(response => {
          if (response.data.company_plant_issuer_addres_city == customerConstruction.default_address.city) {
            /**
             * Se regra específica em tela (configuração de nfs-e - migrate):
             * 'informar código da obra' estiver ativo.
             */
            if (response.data.nfse_load_city_hall_construction_code) {
              /**
               * Preenche com o 'Cód. de obra da prefeitura' com o
               * 'Código padrão quando município da obra igual do emissor'
               */
              this.contract_proposal.city_hall_construction_code =
                response.data.standard_code_for_matching_municipalities;
            }
          } else {
            this.contract_proposal.city_hall_construction_code = '';
          }
        });
    },
    setRange() {
      const term = this.payment_terms_by_method.find((item) => item.id === this.contract_proposal.billing.payment_term_id)
      this.contract_proposal.billing.range = term.range
      this.contract_proposal.billing.range_off = term.range_off
      this.contract_proposal.billing.range_off_type = term.range_off_type
    },
    setMethods(clear = true) {
      let Payment_methods = []
      for (var i of this.contract_proposal.payment_terms_uuids) {
        const payment_methods = this.payment_terms.find(item => item.uuid === i).payment_methods
        Payment_methods = [...Payment_methods, ...payment_methods]
      }
      const objectMap = Payment_methods.reduce((map, object) => {
        map.set(object.id, object);
        return map
      }, new Map())
      this.Payment_methods = Array.from(objectMap, ([_, value]) => value)
      if (clear) {
        this.contract_proposal.billing.payment_method_id = null
      }
      this.Payment_methods = this.Payment_methods.filter((item) => this.contract_proposal.payment_methods_ids.includes(item.id))
      if (!this.contract_proposal.billing.payment_method_id &&
        this.Payment_methods.length === 1 && this.contract_proposal.automatic_billing) {
        this.contract_proposal.billing.payment_method_id = this.Payment_methods[0].id
        this.getPaymentIntermediaries()
      }
      this.getPaymentTerms(clear)
    },
    handleSubmit() {
      if (this.contract_proposal.billing.range && this.contract_proposal.billing.range !== 1 && !this.frequency_selecteds.length) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Informe a frequência de faturamento para continuar',
        });
      }
      this.loadingSave = true;
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });

      if (
        this.contract_proposal.entity && (
          !this.contract_proposal.entity.address ||
          !this.contract_proposal.entity.address.postal_code ||
          !this.contract_proposal.entity.address.state ||
          !this.contract_proposal.entity.address.city ||
          !this.contract_proposal.entity.address.address ||
          !this.contract_proposal.entity.address.number ||
          !this.contract_proposal.entity.address.district
        )
      ) {
        this.$notify({
          type: 'warning',
          message: 'Cadastre endereço para o tomador.'
        });
        return;
      }

      let payment_terms = []
      for (let uuid of this.contract_proposal.payment_terms_uuids) {
        const item = this.payment_terms.find((item) => item.uuid === uuid)
        payment_terms.push(item)
      }
      this.contract_proposal.necessary_approvation = payment_terms.some((item) => item.financial_approval === true)
      if (this.contract_proposal.necessary_approvation) {
        this.contract_proposal.necessary_approvation_with_different = true
      }
      if (this.contract_proposal && this.contract_proposal.entity) {
        this.contract_proposal.status = ContractProposalEnum.ACTIVE;
      }
      this.contract_proposal.invoice_frequency = {...this.frequency_selecteds}
      this.$store
        .dispatch("contractProposal/update", this.contract_proposal)
        .then(response => {
          if (this.contract_proposal && this.contract_proposal.entity) {
            $_totvs.update({...this.contract_proposal.construction, ...{entity_id: this.contract_proposal.entity.id}});
          }
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({type: "danger", message: message});
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    getCompanyPlantIssuers(set_plant_issuer = true) {
      this.loadingCompanyPlantIssuer = true
      this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlant', {
        id: this.contract_proposal.company_plant_id
      }).then(response => {
        const default_plant_issuer = response.data.find(item => item.default)
        if (default_plant_issuer && this.contract_proposal.issuer_fixed && set_plant_issuer) {
          this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
        this.loadingCompanyPlantIssuer = false
      }).catch(error => {
        this.loadingCompanyPlantIssuer = false
      })
    },
    handleSearchConstruction() {
      this.$refs.searchConstruction.handleSearchConstruction(
        "customer_name or construction_name"
      );
    },
    handleSearchCpfCnpj() {
      this.$refs.searchCpfCnpj.handleSearchCpfCnpj(true);
    },
    handleCreateCpfCnpj() {
      this.$refs.createEntity.openModal(true);
    },
    handleChangeStatus(contractProposal, status) {
      this.loading = true
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$store
        .dispatch(
          "contractProposal/fetchSituations")
        .then(response => {
          this.$Progress.finish();
          this.loading = false
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          const item = {...contractProposal}
          item.status = status
          this.$refs.changeStatus.handleChangeStatus(item);
        });
    },
    handleRemoveAdditionalInformation(id, index) {
      this.$Progress.start();
      const payload = {
        id: id
      };
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: 'info',
              message: 'Estamos trabalhando em sua solicitação.'
            });
            this.$store
              .dispatch("contractProposal/remove_comment", payload)
              .then(response => {
                this.contract_proposal.comments.splice(index, 1);
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
              }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleCreateAdditionalObservation(contratctProposalUuid) {
      this.$refs.createAdditionalObservation.openModal(contratctProposalUuid);
    },
    handleEditEntity() {
      this.$refs.editEntity.openModal(true, this.contract_proposal.entity.uuid);
    },
    handleEditConstruction() {
      this.$refs.editConstruction.handleEditModal(this.contract_proposal.construction.uuid);
    },
    active(status, value) {
      if (status == value) {
        return "active";
      }
    },
    filteredPaymentTerms() {
      if (this.entity_is_company) {
        return this.payment_terms.filter((item) => item.legal_person)
      } else {
        return this.payment_terms.filter((item) => item.physical_person)
      }
    },
    sketch(status) {
      if (status === 4) {
        return 'text-white bg-light'
      }
    },
    changeStatus(status) {
      this.contract_proposal.status = status
      this.$store
        .dispatch(
          "contractProposal/show",
          this.$route.params.contract_proposal_uuid
        )
        .then(() => {
          this.loading = false;
        });
    },
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
    async fetch() {
      this.block_iss = false
      this.loadingMethod = true
      this.load_counter = 0;
      //this.loader = this.$loading.show();
      this.$Progress.start();
      await this.fetchCommercialPermissions();
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.load_counter++;
        this.loadingPlant = false;
        this.checkPlantGeneralSettings();
      });
      this.loadingBankAccounts = true
      this.$store.dispatch('bankAccount/fetchItems', {})
        .then(response => {
          this.load_counter++;
          this.loadingBankAccounts = false;
        })
        .catch(error => {
          this.loadingBankAccounts = false;
        })
      this.$store.dispatch("contractContracts/fetchItems").then(() => {
        this.load_counter++;
        this.loadingContract = false;
      });
      this.loading = true;
      this.loadingUsers = true
      this.$store.dispatch("user/getSellersSimplified").then((response) => {
        this.load_counter++;
        this.users = response.data;
        this.loadingUsers = false;
      });
      this.$store
        .dispatch(
          "contractProposal/show",
          this.$route.params.contract_proposal_uuid
        )
        .then((response) => {
          this.load_counter++;
          if (this.contract_proposal && this.contract_proposal.entity) {
            const document_length = onlyNumbers(this.contract_proposal.entity.document).length
            if (document_length <= 11) {
              this.contract_proposal.iss_retain = false
              this.block_iss = true
            } else {
              this.block_iss = false
            }
          }
          this.frequency_selecteds = response.data.invoice_frequency
          this.$store.dispatch("paymentMethod/fetchItems")
            .then(() => {
              this.load_counter++;
              this.loadingPaymentIntermediaries = true
              this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
                this.getPaymentIntermediaries(false)
                this.loadingPaymentIntermediaries = false
              })
              this.loadingMethod = false;
              this.$store.dispatch("paymentTerm/fetchItems").then(() => {
                this.loadingPaymentTerm = false;
                this.setPaymentTermsByMethod(false)
                if (this.contract_proposal.automatic_billing) {
                  this.setMethods(false)
                }
              });
            }).catch(() => {
          })
          const is_seller = this.users.find(item => item.uuid === this.$store.state.auth.user.uuid)
          if (is_seller && !this.contract_proposal.seller) {
            this.contract_proposal.seller = this.$store.state.auth.user.uuid
          }
          this.entity_is_company = false
          if (this.contract_proposal.service_receiver.document.replace(/[^0-9]/g, '') > 11) {
            this.entity_is_company = true
          }
          if (!this.contract_proposal.automatic_billing) {
            this.contract_proposal.billing.company_plant_id = this.contract_proposal.plant.id
          }
          this.getCompanyPlantIssuers(false)
          this.fetchEntityAtachments();
          this.fetchConstructionAtachments();
          this.fetchEntityHistory();
          this.loading = false;
        }).catch(() => {
        this.loading = false;
      });
    },
    SetServiceInvoiceNotes() {
      this.contract_proposal.observation = this.contract_proposal.entity.default_text;
    },
    /**
     * Verifica as configurações gerais da central selecionada para deixar os campos
     * 'cno' e 'Cód. de obra da prefeitura' obrigatórios.
     */
    checkPlantGeneralSettings() {
      const plant = this.plants.find((item) => item.id === this.contract_proposal.company_plant_id);
      this.$store.dispatch("plant/getGeneralSettings", plant.uuid).then((response) => {
        this.is_cno_required_prop_contracts = response.data.is_cno_required_prop_contracts;
        this.is_municipal_work_code_required_prop_contracts = response.data.is_municipal_work_code_required_prop_contracts;
        this.is_art_encapsulation_required = response.data.is_art_encapsulation_required;
        this.load_counter++;
      });
    },
    refresh() {
      this.fetch();
    },
    /**
     * Carrega total de anexos da obra associada ao contrato.
     */
    fetchConstructionAtachments() {
      this.loadingConstructionAttachments = true;
      this.$store.dispatch("constructionAttachment/getTotalAttachmentByConstructionId", this.contract_proposal.construction.id)
        .then((response) => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
          this.load_counter++;
          this.loadingConstructionAttachments = false;
        });
    },
    /**
     * Abre modal de listagem de anexos da obra.
     * @param {number} constructionId
     * @param {string} constructionName
     */

    handleShowConstructionAttachments(constructionId, constructionName) {
      this.$refs.modalConstructionAttachmentList.handleCreateModal(constructionId, constructionName);
    },
    /**
     * Atualiza total de anexos após realizar o upload.
     *  @param {number} constructionId
     */
    updatedTotalConstructionAttachmentAfterUpload(constructionId) {
      this.$store.dispatch('constructionAttachment/getTotalAttachmentByConstructionId', constructionId)
        .then(response => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
        });
    },
    /**
     * Atualiza total de anexos após deletar um anexo.
     */
    updateTotalConstructionAttachmentAfterDeletion() {
      this.totalConstructionAttachments--;
    },
    /**
     * Carrega total de anexos da obra associada ao contrato.
     */
    fetchEntityAtachments() {
      if (this.contract_proposal && this.contract_proposal.entity) {
        this.loadingEntityAttachments = true;
        this.$store.dispatch("entity/getTotalAttachmentsByEntityId", this.contract_proposal.entity.id)
          .then((response) => {
            this.totalEntityAttachments = response.data.total_entity_attachments;
            this.load_counter++;
            this.loadingEntityAttachments = false;
          });
      }
    },
    /**
     * Abre modal de listagem de anexos de clientes/fornecedores.
     * @param {number} entityId
     * @param {string} name
     * @param {string} document
     */
    handleShowEntityAttachments(entityId, name, document) {
      this.$refs.modalEntityAttachmentList.handleCreateModal(entityId, name, document);
    },
    /**
     * Atualiza total de anexos de clientes/fornecedores após realizar o upload.
     * @param {number} entityId
     */
    updatedTotalEntityAttachmentAfterUpload(entityId) {
      this.$store.dispatch('entity/getTotalAttachmentsByEntityId', entityId).then(response => {
        this.totalEntityAttachments = response.data.total_entity_attachments;
      });
    },
    /**
     * Após excluir um anexo de clientes/fornecedores, é atualizado o total de arquivos anexados.
     */
    updateTotalEntityAttachmentAfterDeletion() {
      this.totalEntityAttachments--;
    },
    /**
     * @param {number} status
     */
    getStatusText(status) {
      switch (status) {
        case 0:
          return 'inativo';
        case 1:
          return 'ativo';
        case 2:
          return 'Pendente de revisão';
        case 3:
          return 'Pendente de aprovação';
        case 5:
          return 'concluído';
        default:
          return 'proposta';
      }
    },
    /**
     * @param {number} status
     * @param {object} entity
     */
    getButtonType(status, entity) {
      switch (status) {
        case ContractProposalEnum.INACTIVE:
          return 'danger';
        case ContractProposalEnum.ACTIVE:
          if (entity) {
            return 'success';
          } else {
            return 'light';
          }
        case ContractProposalEnum.COMPLETED:
          return 'primary';
        default:
          return 'light';
      }
    },
    fetchCommercialPermissions() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    },
    /**
     * Busca o histórico de clientes/fornecedores, para determinar se o componente
     * PuzlSelect de 'central' e 'vendedor' devem ser desabilitados ou não.
     *
     * Se 'opened_billing' for maior que zero, a permissão do usuário
     * ($_commercial_permission.contract_editor_and_seller) é verificada.
     *
     * Se 'opened_billing' for igual a zero, qualquer usuário pode editar o PuzlSelect,
     * independentemente de suas permissões.
     */
    fetchEntityHistory() {
      if (this.contract_proposal && this.contract_proposal.entity) {
        this.$store.dispatch("entityHistory/getHistory", {
          filter: {},
          id: this.contract_proposal.entity.id,
        }).then((response) => {
          this.opened_billing = response.data.opened_billing; // Campo 'a faturar' da modal histórico de cliente.
          this.load_counter++;
        });
      }
    },
    /**
     * Abre modal de pesquisa do tomador por documento.
     * @param {object} contract_proposal
     */
    handleShowModalSearchEntity(contract_proposal) {
      // Validações de configurações gerais da central antes de gerar um contrato.
      if (this.is_cno_required_prop_contracts && !this.contract_proposal.cno) {
        this.$notify({
          type: 'warning',
          message: 'O campo CNO é obrigatório para gerar o contrato.'
        });
        return;
      }

      if (this.is_municipal_work_code_required_prop_contracts && !this.contract_proposal.city_hall_construction_code) {
        this.$notify({
          type: 'warning',
          message: 'O campo Cód. de obra da prefeitura é obrigatório para gerar o contrato.'
        });
        return;
      }

      if (this.is_art_encapsulation_required && !this.contract_proposal.art_code) {
        this.$notify({
          type: 'warning',
          message: 'O campo Art/nº de controle do encapsulamento é obrigatório para gerar o contrato.'
        });
        return;
      }

      this.$refs.modalSearchEntity.openModal(contract_proposal);
    },
  },
  async mounted() {
    await this.fetchFinancialLimits(this.$user.id)
    this.fetch()
  }
};
</script>

<style scoped>
.frequency:not(.frequency-selected) {
  font-family: system-ui;
  font-size: 12px;
  min-width: 25px;
  padding: 6px;
}

.frequency:hover {
  background-color: #dbd9d9;
}

.frequency-selected {
  font-family: system-ui;
  font-size: 12px;
  padding: 6px;
  color: white;
  min-width: 25px;
  background-color: #e65119;
}

.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}

.btn-custom {
  position: fixed !important;
  bottom: 50px;
  z-index: 100;
  width: 93px;
  height: 91px;
  transform: scale(0.7);
  border-radius: 50%;
  right: 80px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.btn-custom:hover {
  -webkit-transform: scale(0.8) !important;
  transform: scale(0.8) !important;
}

.span-custom {
  position: static !important;
  height: unset !important;
  border-radius: 50%;
  background-color: transparent;
}

.icon-custom {
  font-size: 45px;
}

.btn-loading {
  pointer-events: none;
  opacity: 0.9;
}

@media (min-width: 540px) {
  .btn-custom {
    transform: scale(0.75);
  }
}

.rounded-circle {
  border: 1px solid #D3D3D3;
}

.background-color-circle {
  background-color: #f3f4fa;
}

.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}

.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.base-button:hover .text-dark {
  color: white !important;
}

/* Botão anexos */
.custom-button {
  background-color: white;
  border: 2px solid #198754;
  border-radius: 50px;
  padding: 0px -1px;
  width: max-content;
}

.custom-button .col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button .vertical-line {
  border-left: 2px solid #198754;
  height: 100%;
  margin-left: -2px;
  margin-right: -2px;
}

.custom-button {
  height: 30px;
}
</style>
